<template>
  <div>
    <template v-if="value.amount">
      {{ $t(value.recurrency) }}
      <strong>
        {{ value.amount }}
        {{ $t(value.currency) }}
      </strong>
      / {{ $t("payer-" + value.payer) }}
    </template>
    <template v-else>
      <strong>
        ingyenes
      </strong>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  }
};
</script>
