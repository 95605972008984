<template>
  <div>
    <template v-if="isNaN(parseInt(value.to))">
      <!-- only from -->
      min <strong>{{ value.from }}</strong>
    </template>
    <template v-else-if="isNaN(parseInt(value.from))">
      <!-- only to -->
      max <strong>{{ value.to }}</strong>
    </template>
    <template v-else-if="value.from === value.to">
      <!-- they are equal -->
      <strong>{{ value.to }}</strong>
    </template>
    <template v-else>
      <!-- otherwise -->
      <strong>{{ value.from }}</strong
      >-<strong>{{ value.to }}</strong>
    </template>
    fő
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  }
};
</script>
