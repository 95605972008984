var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-list-group',{attrs:{"flush":_vm.flush}},_vm._l((_vm.value),function(link,i){return _c('b-list-group-item',{key:i,staticClass:"py-2 px-2 px-sm-3",attrs:{"href":link.url,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.iconVisible[link.url])?_c('b-img',{staticClass:"mr-2",attrs:{"rounded":"","src":_vm.meta.find(function (m) { return m.url === link.url; }).logo,"width":"20"},on:{"error":function($event){_vm.e[i] = true}}}):(
          _vm.meta.find(function (m) { return m.url === link.url; }) &&
            _vm.meta.find(function (m) { return m.url === link.url; }).type
        )?[_c('div',{staticClass:"d-flex align-items-center justify-content-center mr-2 flex-shrink-0",staticStyle:{"width":"20px"}},[(_vm.meta.find(function (m) { return m.url === link.url; }).type === 'pdf')?_c('b-icon-file-earmark-arrow-down',{attrs:{"variant":"primary"}}):_vm._e(),(_vm.meta.find(function (m) { return m.url === link.url; }).type === 'zip')?_c('b-icon-file-earmark-arrow-zip',{attrs:{"variant":"primary"}}):_vm._e()],1)]:_vm._e(),_c('b-link',{staticClass:"card-link mr-2",style:({
          overflow: 'hidden',
          'text-overflow': 'ellipsis',
          'white-space': 'nowrap'
        }),attrs:{"href":link.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.meta && _vm.meta.find(function (m) { return m.url === link.url; }) && _vm.meta.find(function (m) { return m.url === link.url; }).title ? _vm.meta.find(function (m) { return m.url === link.url; }).title + " | " + _vm.displayUrl(link.url) : _vm.displayUrl(link.url))+" ")]),_c('b-tag',{staticClass:"mr-0 mr-sm-2",attrs:{"no-remove":"","variant":"light"}},[_vm._v(" "+_vm._s(_vm.$t(link.type))+" ")]),_c('b-icon-box-arrow-up-right',{staticClass:"ml-auto d-none d-sm-block",attrs:{"variant":"primary"}})],2)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }