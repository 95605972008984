<template>
  <div class="richtext-editor" :class="{ editable: !readonly, scroll }">
    <editor-content
      v-if="bottomMenuBar"
      class="editor__content"
      :editor="editor"
    />

    <editor-menu-bar
      :editor="editor"
      v-slot="{ commands, isActive }"
      v-if="!readonly"
    >
      <div class="menubar my-1">
        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <b-icon icon="type-bold" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <b-icon icon="type-italic" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <b-icon icon="type-strikethrough" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <b-icon icon="type-underline" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.code() }"
          @click="commands.code"
        >
          <b-icon icon="code" />
        </b-button>

        <b-dropdown variant="text">
          <template #button-content>
            <b-icon-type />
          </template>
          <b-dropdown-item
            class="menubar__button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
          >
            <b-icon icon="paragraph" />
          </b-dropdown-item>
          <b-dropdown-item
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
          >
            <b-icon icon="type-h1" />
          </b-dropdown-item>
          <b-dropdown-item
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            <b-icon icon="type-h2" />
          </b-dropdown-item>
          <b-dropdown-item
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            <b-icon icon="type-h3" />
          </b-dropdown-item>
        </b-dropdown>

        <!--b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <b-icon icon="paragraph" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          <b-icon icon="type-h1" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          <b-icon icon="type-h2" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          <b-icon icon="type-h3" />
        </b-button-->

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <b-icon icon="list-ul" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <b-icon icon="list-ol" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <b-icon icon="blockquote-left" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <b-icon icon="code-square" />
        </b-button>

        <b-button
          class="menubar__button"
          variant="text"
          @click="commands.horizontal_rule"
        >
          <b-icon icon="hr" />
        </b-button>

        <b-button class="menubar__button" variant="text" @click="commands.undo">
          <b-icon icon="arrow-counterclockwise" />
        </b-button>

        <b-button class="menubar__button" variant="text" @click="commands.redo">
          <b-icon icon="arrow-clockwise" />
        </b-button>
      </div>
    </editor-menu-bar>

    <editor-content
      v-if="!bottomMenuBar"
      class="editor__content"
      :editor="editor"
    />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Placeholder
} from "tiptap-extensions";
export default {
  name: "RichttextEditor",
  props: {
    value: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    bottomMenuBar: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: true
    }
  },
  components: {
    EditorContent,
    EditorMenuBar
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            emptyNodeText: "Write something …",
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          })
        ],
        onUpdate: (editor) => {
          this.$emit(
            "input",
            editor.state.doc.textContent ? editor.getJSON() : null
          );
        },
        content: this.value,
        editable: !this.readonly
      })
    };
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    requestFocus() {
      this.$nextTick(() => {
        this.editor.focus();
      });
    }
  },
  watch: {
    value: {
      handler(value) {
        if (
          JSON.stringify(value) !== JSON.stringify(this.editor.getJSON()) &&
          JSON.stringify(value) !== JSON.stringify(this.editor.getHTML())
        )
          this.editor.setContent(value);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/variables";

.richtext-editor {
  &.scroll {
    .ProseMirror {
      max-height: 300px;
      overflow-y: auto;
    }
  }
  .ProseMirror {
    &-focused:focus {
      outline: 0;
      &[contenteditable="true"] {
        border-color: #18ffff !important;
        box-shadow: 0 0 0 0.2rem rgba(0, 151, 151, 0.25);
      }
    }
    blockquote {
      color: $primary;
      padding-left: 8px;
      margin-left: 8px;
      border-left: solid 4px $primary;
      border-radius: 5px;
    }
    pre {
      background-color: $body-bg; // rgba(0, 0, 0, 0.05);
      padding: 4px 8px;
      border-radius: 5px;
      word-break: normal !important;
      word-wrap: normal !important;
      white-space: pre !important;
    }
    &[contenteditable="true"] {
      padding: 4px 8px;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/variables";

.menubar__button {
  &.is-active {
    &,
    & * {
      color: $primary;
    }
  }
}
</style>
