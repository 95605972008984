<template>
  <div>
    <strong>
      {{ value }}
    </strong>
    {{ postfix }}
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    postfix: {
      type: String,
      default: ""
    }
  }
};
</script>
