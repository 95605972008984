<template>
  <b-list-group :flush="flush">
    <b-list-group-item
      v-for="(link, i) in value"
      :key="i"
      :href="link.url"
      target="_blank"
      class="py-2 px-2 px-sm-3"
    >
      <div class="d-flex align-items-center">
        <b-img
          v-if="iconVisible[link.url]"
          rounded
          :src="meta.find((m) => m.url === link.url).logo"
          class="mr-2"
          width="20"
          @error="e[i] = true"
        />
        <template
          v-else-if="
            meta.find((m) => m.url === link.url) &&
              meta.find((m) => m.url === link.url).type
          "
        >
          <div
            class="d-flex align-items-center justify-content-center mr-2 flex-shrink-0"
            style="width: 20px"
          >
            <b-icon-file-earmark-arrow-down
              v-if="meta.find((m) => m.url === link.url).type === 'pdf'"
              variant="primary"
            />
            <b-icon-file-earmark-arrow-zip
              v-if="meta.find((m) => m.url === link.url).type === 'zip'"
              variant="primary"
            />
          </div>
        </template>
        <b-link
          :href="link.url"
          target="_blank"
          class="card-link mr-2"
          :style="{
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap'
          }"
        >
          {{
            meta &&
            meta.find((m) => m.url === link.url) &&
            meta.find((m) => m.url === link.url).title
              ? meta.find((m) => m.url === link.url).title +
                " | " +
                displayUrl(link.url)
              : displayUrl(link.url)
          }}
        </b-link>
        <b-tag no-remove variant="light" class="mr-0 mr-sm-2">
          {{ $t(link.type) }}
        </b-tag>
        <b-icon-box-arrow-up-right
          class="ml-auto d-none d-sm-block"
          variant="primary"
        />
      </div>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
import displayUrl from "@/utils/displayUrl";
export default {
  props: {
    value: {
      required: true
    },
    flush: {
      type: Boolean,
      default: false
    },
    meta: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({ e: {} }),
  computed: {
    iconVisible() {
      //console.log(this.e);
      const self = this;
      return Object.fromEntries(
        this.value.map(({ url }, i) => [
          url,
          self.meta &&
            self.meta.find((m) => m.url === url) &&
            self.meta.find((m) => m.url === url).logo &&
            !self.e[i]
        ])
      );
    }
  },
  methods: {
    displayUrl
  }
};
</script>
